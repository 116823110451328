.slideshow-container {
    display: flex;
    overflow-x: auto;
    gap: 20px;
    padding: 0px 15px;
    align-items: flex-start;
    width: calc(100% + 8.3vw); /* For the overflow effect */
    margin-right: -20px; /* For the overflow effect */
    margin-left: -20px; /* For the overflow effect */
  }
  
  .image-container img {
    min-width: 286px;
    height: 227px;
    object-fit: cover;
  }

  /* Desktop view */
@media (min-width: 1024px) {
  .slideshow-container {
    gap: 30px;
    padding: 0px 60px;
    width: calc(100% + 120px); /* For the overflow effect */
    margin-right: -60px; /* For the overflow effect */
    margin-left: -60px; /* For the overflow effect */
}

.image-container img {
    min-width: 530px;
    height: 480px;
    border-radius: 5px;
  }
}
