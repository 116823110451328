.box-desktop {
    padding: 15px 20px;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.50);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    align-self: start;
}

.desc-desktop {
    width: 400px;
    color: #FFF;
    text-align: justify;
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
