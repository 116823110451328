.outlined-text {
    color: var(--White);
    text-align: center;
    font-family: 'Archivo Black', sans-serif;
    font-size: min(13vw, 50px); /* 50px / 667px * 100 */
    font-weight: 400;
    letter-spacing: 1vh; /* 3.5px / 375px * 100 */
    line-height: 1;
    justify-content: center;
    -webkit-text-stroke: 2px var(--Primary-1); /* 2px / 375px * 100 */
    width: 92.8vw; /* 347px / 375px * 100 */
}

@media (min-width: 1024px) {
    .outlined-text {
        height: 156px;
        color: var(--Gray-300, #DBE5EC);
        font-size: 72px;
        letter-spacing: 3.5px;
        -webkit-text-stroke: 0;
    }
}
