.menu {
    width: 238px;
    height: 100%;
    background: var(--White);
    position: fixed;
    left: 0;
    top: 0;
    padding: 10px 5px 10px 20px;
    z-index: 1000;
    display: flex;  /* Updated to flex */
    flex-direction: column;  /* Updated to column */
    align-items: flex-end;
    gap: 30px;
    transform: translateX(-100%);
    transition: transform 800ms ease-in-out;
    pointer-events: none;
}

.menu.open {
    transform: translateX(0);
    transition: transform 800ms ease-in-out;
    pointer-events: auto;
}

.close-icon {
    cursor: pointer; /* Makes the X icon clickable */
}

.menu-items {
    line-height: 30px;
    letter-spacing: 0em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: flex-start;
    justify-content: flex-start; /* Aligns text to the left */
    gap: 21px;
}

.menu-item {
    width: 145px;
    height: 34px;
    color: var(--Black);
    font-family: 'ClashGrotesk-Medium', sans-serif; /* Make sure to provide a fallback font */
    font-size: 24px;
    line-height: normal;
    text-align: left;
    text-decoration: none; /* Removes underline from the link */
}

.flags {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.25);
    z-index: 999; /* Make sure this is below the menu's z-index */
  }

  /*** CSSTransition ***/
  /* 
   * This class is added at the start of the enter transition. 
   * It sets the initial state for the component entering the DOM. 
   * In this case, the overlay is completely transparent (opacity: 0).
   */
   .overlay-enter {
    opacity: 0;
  }
  
  /* 
   * This class is added after 'overlay-enter' and removed at the end of the enter transition.
   * It defines the transition effect to reach the final state of the entering component.
   * Here, the overlay gradually becomes fully visible (opacity: 1) over 800ms.
   */
  .overlay-enter-active {
    opacity: 1;
    transition: opacity 800ms ease-in-out;
  }
  
  /* 
   * This class is applied at the start of the exit transition.
   * It defines the initial state of the component leaving the DOM.
   * The overlay is fully visible at this point (opacity: 1).
   */
  .overlay-exit {
    opacity: 1;
  }
  
  /* 
   * This class is added after 'overlay-exit' and removed when the exit transition completes.
   * It defines the transition effect to reach the final state of the exiting component.
   * The overlay becomes completely transparent (opacity: 0) over 800ms, making it disappear.
   */
  .overlay-exit-active {
    opacity: 0;
    transition: opacity 800ms ease-in-out;
  }

  @media (min-width: 1024px) {
    .menu {
      width: 100%;
      height: 60px;
      background: none;
      position: relative;
      z-index: 1000;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;
      transform: translateX(0);
      transition: none;
      pointer-events: all;
    }

    .menu-items {
      letter-spacing: 0em;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-end; 
      gap: 35px;
    }
  
    .menu-item {
      display: flex;
      align-items: center;
      width: auto;
      height: 39px;
      padding: 10px;
      color: var(--White, #FFF);
      text-align: center;
      font-family: 'ClashGrotesk-Medium', sans-serif;
      font-size: 32px;
      transition: color 0.3s ease-in-out;
      text-transform: uppercase;
    }

    .menu-item.active {
      color: var(--Secondary-2, #D6A04D);
    }

    .close-icon, .overlay {
      display: none;
    }
  }  
