.info-box-inter-font {
    display: flex;
    padding: 10px 23px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.65);
    box-shadow: 2px 2px 2px 0px rgba(8, 84, 127, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.info-title-inter-font {
    color: var(--White);
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 127.523%;
    max-width: 320px;
}

.info-description-inter-font {
    color: var(--White);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 135.523%;
    text-align: center;
    max-width: 320px;
}

@media (min-width: 1024px) {
    .info-box-inter-font {
        gap: 20px;
        background: var(--White, #FFF);
    }

    .info-title-inter-font {
        color: var(--dark-blue, #161543);
        font-size: 30px;
        width: 100%;
        max-width: 572px;
    }

    .info-description-inter-font {
        color: var(--muted-blue, #7687A1);
        font-size: 28px;
        width: 100%;
        max-width: 572px;
    }
}
