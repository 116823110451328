.header {
    display: flex;
    width: 100%;
    height: 55px;
    padding: 5px 10px;
    justify-content: space-between;
    flex-shrink: 0;
    background: rgba(0, 0, 0, 0.40);
    position: fixed; 
    top: 0;
    z-index: 1000;
}

.menu-icon, .logo {
    width: 45px;
    height: 45px;
    flex-shrink: 0;
    cursor: pointer; /* Makes the logo clickable */
    background-size: cover;
}

.header .logo {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/assal-green-energy.appspot.com/o/assets%2Fimages%2Flogos%2Flogo_45.png?alt=media');
}

@media (min-width: 1024px) {
    .header {
        height: 105px;
        padding: 10px 20px;
        align-items: center;
        background: none;
        position: relative;
        z-index: 0;
    }

    .header.home .logo {
        background-image: url('https://firebasestorage.googleapis.com/v0/b/assal-green-energy.appspot.com/o/assets%2Fimages%2Flogos%2Fassal_logo_no_margin.png?alt=media');
        width: 134px;
        height: 85px;
    }

    .header .logo {
        background-image: url('https://firebasestorage.googleapis.com/v0/b/assal-green-energy.appspot.com/o/assets%2Fimages%2Flogos%2Flogo_64.png?alt=media');
        width: 64px;
        height: 64px;
    }
  
    .menu-icon {
        display: none;
    }
}
