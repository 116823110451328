.box-common {
    display: inline-flex;
    width: 100%;
    max-width: 345px;
    padding: 15px;
    border-width: 3px;
    border-style: solid;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.info-box-dark {
    border-color: var(--White);
    background: rgba(4, 10, 13, 0.65);
}

.info-box-light {
    border-color: var(--Grey-300);
    background: var(--White);
  }

.text-common {
    width: 100%;
    text-align: justify;
    font-size: 16px;
    font-weight: 600;
}

.info-text-dark {
    color: var(--White);;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    line-height: 135.2%;
}
  
.info-text-light {
    color: var(--muted-blue);
    font-family: 'Inter', 'sans-serif';
}

/* Desktop view */
@media (min-width: 1024px) {
    .box-common {
        box-sizing: border-box;
        width: 100%;
        max-width: 100%;
        padding: 15px 20px;
        border-width: 0px;
        border-style: none;
        border-radius: 5px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }

    .info-box-dark {
        border: none;
        background: rgba(4, 10, 13, 0.50);
    }

    .text-common {
        width: 100%;
        text-align: justify;
        font-size: 22px;
    }

    .info-text-dark {
        color: var(--White);;
        font-family: 'Inter', sans-serif;
        font-weight: 600;
        line-height: 135.2%;
    }
}
