/* Define CSS variables for commonly used values */
:root {
  --input-width: 286px;
  --transition-duration: 500ms;
  --transition-timing: ease-in-out;
}

.message-container {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  z-index: 2000;
}

.back-icon {
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
}

.message-enter {
  transform: translateX(100%);
  }
  
.message-enter-active {
  transform: translateX(0);
  transition: transform var(--transition-duration) var(--transition-timing);
}

.message-exit {
  transform: translateX(0);
}

.message-exit-active {
  transform: translateX(100%);
  transition: transform var(--transition-duration) var(--transition-timing);
}

.message-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 335px;
  height: 600px;
  padding: 30px 0 15px;
  margin: 47px auto 20px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.45);
}

.inputs-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
  width: 100%;
  min-height: 480px;
}

.message-text {
  width: var(--input-width);
  color: var(--Accent-1);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 40px;
  font-weight: 300;
  line-height: 1.116;
  margin: 0;
}

.input-field, .input-textarea {
  width: var(--input-width);
  padding: 0 5px;
  border: none;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-style: italic;
  color: var(--dark-gray);
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.116;
}

.input-field::placeholder, .input-textarea::placeholder {
  color: var(--dark-gray);
}

.input-field {
  height: 48px;
}

.input-textarea {
  height: 190px;
  resize: none;
  padding-top: 10px;
}

.mandatory-wrapper {
  position: relative;
  display: inline-block;
}

.mandatory-wrapper::before {
  font-family: 'Source Sans Pro', sans-serif;
  content: "*";
  color: var(--Black);
  position: absolute;
  margin-left: -15px;
  margin-top: 5px;
  font-size: 20px;
}

.mandatory-note {
  display: flex;
  align-items: flex-start;
  font-size: 10px;
  font-family: 'Source Sans Pro', sans-serif;
  color: var(--dark-gray, #333);
  margin: 0 0 15px 0;
}

.invalid-email .input-field {
  border: 1px solid red;
}

@media (min-width: 1024px) {
  .message-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 580px;
    height: 680px;
    border-radius: 10px;
    padding: 40px;
    box-shadow: 0px 6px 4px 0px rgba(0, 0, 0, 0.25), 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
    pointer-events: auto; /* This will disable mouse events on elements underneath */
  }

  .message-enter {
    transform: translate(-50%, 100%);
  }
  
  .message-enter-active {
    transform: translate(-50%, -50%);
    transition: transform var(--transition-duration) var(--transition-timing);
  }
  
  .message-exit {
    transform: translate(-50%, -50%);
  }

  .message-exit-active {
    transform: translate(-50%, 100%);
    transition: transform var(--transition-duration) var(--transition-timing);
  }
  .message-box {
    width: 500px;
    height: 600px;
    padding: 30px 50px 15px 50px;
    margin: 0;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.45);
  }

  .inputs-container {
    align-items: flex-start;
  }

  .message-text {
    color: var(--Grey-900, #040A0D);
    font-weight: 400;
    line-height: 111.646%;
    letter-spacing: 2.2px;
  }

  .input-field, .input-textarea {
    font-size: 18px;
  }
  
  .input-textarea {
    padding-top: 10px;
    width: 393px;
  }

  .back-icon:hover path {
    fill: var(--White, #FFF);
  }

  .mandatory-note {
    width: 100%;
    justify-content: flex-start;
  }
}
