:root {
  --Primary-1: #08547F;
  --Primary-2: #0D7C94; /* Not used yet */
  --Primary-3: #84D5FF; /* Not used yet */

  --Secondary-1: #BE9416;
  --Secondary-2: #D6A04D;
  --Secondary-3: #EBCF6A; /* Not used yet */

  --Accent-1: #0B2C0B;
  --Accent-2: #597022; /* Not used yet */
  --Accent-3: #869B55; /* Not used yet */

  --Grey-900: #040A0D; /* Not used yet */
  --Grey-700: #304853;
  --Grey-500: #98B3C0;
  --Grey-300: #DBE5EC;
  --Black: #000;
  --White: #FFF;

  --dark-blue: #161543;
  --muted-blue: #7687A1;
  --dark-gray: #696565;

  --bg-gradient: linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%);
}

* {
  box-sizing: border-box;
}

.App {
  display: flex;
  flex-direction: column;
  /*background-position: -244.139px; */
  background-image: var(--bg-gradient), var(--bg-image);     
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 100vh;
}

.App.home {
  --bg-image: url('https://firebasestorage.googleapis.com/v0/b/assal-green-energy.appspot.com/o/assets%2Fimages%2Fhome_bg.webp?alt=media');
}

.App.wind {
  --bg-image: url('https://firebasestorage.googleapis.com/v0/b/assal-green-energy.appspot.com/o/assets%2Fimages%2Fwind_bg.webp?alt=media');
}

.App.battery {
  --bg-image: url('https://firebasestorage.googleapis.com/v0/b/assal-green-energy.appspot.com/o/assets%2Fimages%2Fbattery_bg.webp?alt=media');
}

.App.hybrid {
  --bg-image: url('https://firebasestorage.googleapis.com/v0/b/assal-green-energy.appspot.com/o/assets%2Fimages%2Fhybrid_bg.webp?alt=media');
}

.App.contact {
  --bg-image: url('https://firebasestorage.googleapis.com/v0/b/assal-green-energy.appspot.com/o/assets%2Fimages%2Fcontact_bg.webp?alt=media');
}

body {
  margin: 0;
  padding: 0;
}

.page-container {
  display: flex;
  flex-direction: column;
  row-gap: 3vh;
  align-items: center;
  padding: 0px 4vw 0px 4vw;
  margin: 8vh 0px 3vh 0px;
  border: 0px;
  overflow-x: hidden;
}

.content {
  flex-grow: 1;
}

.top-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 0;
  height: calc(100vh - 55px);
}


@media (min-width: 1024px) {
  :root {
    --bg-gradient: linear-gradient(0deg, rgba(0, 0, 0, 0.55) 0%, rgba(0, 0, 0, 0.55) 100%);
  }

  .page-container {
    row-gap: 30px;
    padding: 0px 60px;
    margin: 45px 0px 50px 0px;
  }
}

/* @media (min-width: 1650px) {
  .page-container {
    max-width: 1650px;
    margin-left: auto;
    margin-right: auto;
  }
} */
