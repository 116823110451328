.text-slideshow-container {
    display: flex;
    overflow-x: auto;
    gap: 10px;
    padding: 0px 15px;
    align-items: flex-start;
    width: calc(100% + 8.3vw); /* For the overflow effect */
    margin-right: -20px; /* For the overflow effect */
    margin-left: -20px; /* For the overflow effect */
  }
  
  .text-slideshow-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 320px;
    padding: 16px;
    border-radius: 10px;
    background: var(--White);
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
  }
  
  .text-slideshow-item .image {
    width: 128px;
    height: 128px;
    border-radius: 160px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  
  .text-slideshow-item .title {
    color: var(--dark-blue);
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 120%;
    margin-top: 16px;
  }
  
  .text-slideshow-item .description {
    color: var(--muted-blue);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    margin-top: 5px;
    align-self: stretch;
  }

  @media (min-width: 1024px) {
    .text-slideshow-container {
      padding: 0px 60px;
      width: calc(100% + 120px); /* For the overflow effect */
      margin-right: -60px; /* For the overflow effect */
      margin-left: -60px; /* For the overflow effect */
    }

    .text-slideshow-item {
      min-width: 380px;
    }

    .text-slideshow-item .image {
      width: 170px;
      height: 170px;
    }

    .text-slideshow-item .description {
      font-size: 20px;
    }
  
    .text-slideshow-item .title {
      font-size: 30px;
    }
}
