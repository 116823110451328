.custom-button {
    display: inline-flex;
    padding: 10px 15px;
    justify-content: center;
    align-items: center;
    height: auto;
    min-width: 90px;
    white-space: nowrap;
    color: var(--Grey-700);
    font-family: 'ClashGrotesk-Medium'; /* Updated font-family */
    font-size: 24px; /* Updated font-size */
    text-transform: uppercase;
    border-radius: 5px;
    background-color: var(--Secondary-2);
    box-shadow: 4px 8px 8px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.custom-button:hover, .custom-button:active {
    color: var(--White);
    background-color: var(--Secondary-1);
    box-shadow: 8px 10px 8px 0px rgba(0, 0, 0, 0.25), 0px 6px 6px 0px rgba(0, 0, 0, 0.25);
}

.custom-button.disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

