.battery-chapter-title {
    color: var(--Grey-300);
    text-align: left;
    font-family: 'Inter', sans-serif;
    font-size: 40px;
    line-height: normal;
    font-weight: 800;
    height: 87px
  }

  @media (min-width: 1024px) {
    .battery-rows {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      max-width: 1530px;
      gap: 90px;
      margin-bottom: 70px;
    }

    .buttons-column {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 20px;
    }

    .battery-chapter-title {
        width: 100%;
        max-width: 800px;
        font-family: 'Archivo Black', sans-serif;
        font-size: 72px;
        height: 100%;
        line-height: 1.1;
    }
}
