.contact-header {
    height: 15vh;
    color: var(--White);
    font-family: 'Archivo Black', sans-serif;
    font-size: 35px;
    line-height: 123.8%;
    margin-top: 5vh;
}
  
.map-container {
    position: relative;
    width: 345px;
    height: 297px;
    border-radius: 5px;
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.45);
    margin-bottom: 2vh;
 }
  
.address-text {
    position: absolute;
    bottom: 15px;
    width: 275px;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
    color: var(--Black);
    text-align: center;
    font-family: 'ClashGrotesk-Semibold', sans-serif;
    font-size: 18px;
    line-height: 123.8%;
    padding: 5px 0px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.60);
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
    transition: all 0.3s ease-in-out;
 }

.address-text:hover {
  background-color: var(--Grey-500);
}

 @media (min-width: 1024px) {
    .contact-row {
        width: 100%;
        display: flex;
        align-items: center; /* Optional: Aligns items vertically in the center */
        justify-content: space-between; /* Spreads items evenly with equal space between them */
        flex-wrap: nowrap; /* Prevents wrapping onto new lines */
        width: 100%;
        max-width: 1530px;
        gap: 50px;
    }

    .contact-left-column {
        display: flex;
        flex-direction: column;
        gap: 0px;
    }

    .contact-header {
        width: 570px;
        height: 100%;
        font-size: 72px;
        line-height: normal;
        margin-top: 0;
    }

    .map-container {
        width: 500px;
        height: 500px;
        border-radius: 10px;
        box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.45), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }

    .address-text {
        width: 100%;
        max-width: 367px;
        margin: 0 auto 30px auto;
        color: var(--Black);
        font-size: 25px;
        padding: 5px 15px;
     }

     .contact-info-box {
        width: 570px;
        padding: 15px 10px;
        border-radius: 5px;
        background: rgba(255, 255, 255, 0.35);
      }
      
      .info-row {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
      }
      
      .contact-info-text {
        display: flex;
        width: 160px;
        height: 45px;
        flex-direction: column;
        justify-content: center;
        color: var(--Grey-900, #040A0D);
        font-family: 'Archivo Black', sans-serif;
        font-size: 32px;
        font-weight: 400;
        letter-spacing: 1.12px;
      }
      
      .info-detail {
        display: flex;
        height: 45px;
        flex-direction: column;
        justify-content: center;
        color: var(--Grey-900, #040A0D);
        font-family: 'ClashGrotesk-Semibold', sans-serif;
        font-size: 25px;
        line-height: 123.8%; /* 30.95px */
        letter-spacing: 0.875px;
      }

      .backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); /* Adjust color and opacity as needed */
        z-index: 1900; /* Ensure this is higher than the z-index of other elements */
      }

      .fade-enter {
        opacity: 0;
      }
      
      .fade-enter-active {
        opacity: 1;
        transition: opacity 500ms;
      }
      
      .fade-exit {
        opacity: 1;
      }
      
      .fade-exit-active {
        opacity: 0;
        transition: opacity 500ms;
      }

      .map-container:hover .address-text {
        background-color: var(--Grey-500);
      }
      
}
