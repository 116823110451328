/* Common scrollbar styling */
.custom-scrollbar::-webkit-scrollbar {
    height: 8px;
    width: 3px;
}

.custom-scrollbar::-webkit-scrollbar-track {
    background-color: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: var(--Grey-500, #98B3C0);
    border-radius: 5px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: var(--Grey-300, #DBE5EC);
}
