.footer {
    display: flex;
    width: 100%;
    height: 152px;
    justify-content: center;
    flex-shrink: 0;
    align-items: center;
    position: relative;
    bottom: 0;
    z-index: 999;
}

.default {
    background: rgba(255, 255, 255, 0.50);
}

.extra-info {
    flex-direction: column;
    background: rgba(0, 0, 0, 0.30);
}

.footer-logo img {
    width: 181px;
    height: 118px;
    object-fit: cover; /* This will ensure the image covers the area without stretching */
}
  
.logo-container img {
  width: 145px;
  height: 76px;
  object-fit: cover;
}
  
.footer-row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    width: 100%;
}

.footer-row-inner {
    display: grid;
    grid-template-columns: auto min-content;
    gap: 5px;
    align-items: center;
}

.footer-row p {
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--White);
    text-align: center;
    font-family: 'ClashGrotesk-Semibold', sans-serif;
    font-size: 20px;
    line-height: 123.8%;
    margin: 0;
}

@media (min-width: 1024px) {
    .footer {
        height: 306px;
        padding: 10px 20px;
        margin: 0;
    }

    .default {
        background: rgba(0, 0, 0, 0.70);
    }

    .footer-desktop {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      
      .footer-text {
        width: 198px;
        height: 112px;
        color: #575158;
        font-family: Inter;
        font-size: 20px;
        font-weight: 600;
        text-transform: uppercase;
        place-self: self-start;
      }
      
      .footer-partner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        align-self: self-start;
        gap: 30px;
      }
      
      .partner-text {
        width: 171px;
        height: 30px;
        color: #575158;
        text-align: center;
        font-family: Inter;
        font-size: 22px;
        font-weight: 600;
      }
      
      
      
      
      
      
}
