.coming-soon-box {
    width: 292px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.50);
    display: flex;
    flex-direction: column;
    gap: 14px;
    justify-content: center;
    align-items: center;
    margin-top: 108px;
    align-self: center;
  }
  
  .coming-soon-text {
    width: 269px;
    height: 51px;
    color: var(--Black);
    text-align: center;
    font-family: 'Shadows Into Light Two', sans-serif;
    font-size: 38px;
    line-height: 93.647%;
    margin-top: 22px;
  }
  
  .stay-tuned-text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 197px;
    height: 61px;
    color: var(--Grey-700);
    text-align: center;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 22px;
    font-weight: 700;
    line-height: 93.647%;
    margin-bottom: 25px;
  }

  @media (min-width: 1024px) {
    .coming-soon-box {
      width: 416px;
      background: rgba(255, 255, 255, 0.35);
      margin-top: 50px;
      gap: 47px;
    }

    .coming-soon-text {
      color: var(--Accent-1, #0B2C0B);
      font-size: 50px;
      margin-top: 41px;
    }

    .stay-tuned-text {
      color: var(--Accent-1, #0B2C0B);
      font-size: 28px;
      margin-bottom: 78px;
    }

    .hybrid-title-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      width: 100%;
      max-width: 1530px;
    }
}
