.chapter-title {
    color: var(--Grey-300);
    text-align: right;
    font-family: 'ClashGrotesk-Bold', sans-serif;
    font-size: 40px;
    line-height: normal;
    height: 40px;
    width: 100%;
    max-width: 345px;
}

@media (min-width: 1024px) {
    .chapter-title {
        text-align: left;
        font-family: 'Archivo Black', sans-serif;
        font-size: 72px;
        height: 100%;
    }

    .info-box-vertigo-text {
        display: flex;
        width: 100%;
        max-width: 1530px;
    }

    .india-content {
        display: flex;
        justify-content: space-between;
        gap: 45px;
        width: 100%;
        max-width: 1530px;
    }
}
