.text-only-slideshow-container {
    display: flex;
    overflow-x: auto;
    gap: 10px;
    padding: 0px 15px;
    align-items: flex-start;
    width: calc(100% + 8.3vw); /* For the overflow effect */
    margin-right: -20px; /* For the overflow effect */
    margin-left: -20px; /* For the overflow effect */
}

.text-only-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 215px;
    height: 280px;
    padding: 10px 8px;
    border-radius: 10px;
    background: var(--White);
    box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.50);
}

.text-only-item .title {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 160px;
    max-width: 180px;
    height: 70px;
    color: var(--dark-blue);
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 700;
    margin: 0 auto;
}

.text-only-item .description {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    min-height: 180px;
    margin: 6px auto;
    color: var(--muted-blue);
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 400;
}

@media (min-width: 1024px) {
    .text-only-slideshow-container {
        flex-wrap: wrap;
        gap: 30px;
        min-width: 565px;
        max-width: 937px;
        justify-content: flex-end;
    }

    .text-only-item {
        width: 250px;
        height: 320px;
        padding: 15px 13px 10px 13px;
    }

    .text-only-item .title {
        max-width: 210px;
        color: var(--Secondary-1, #BE9416);
        font-size: 22px;
    }

    .text-only-item:nth-child(2n) .title {
        color: var(--dark-blue);
    }

    .text-only-item .description {
        align-items: self-start;
        width: 225px;
        min-height: 180px;
        margin: 30px auto 0px auto;
        font-size: 18px;
    }
}
