.faq-section {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 0 5px;
    border-radius: 40px;
    background: rgba(0, 0, 0, 0.40);
}
  
.faq-section h2 {
    width: 106px;
    height: 50px;
    margin: 0;
    color: var(--Grey-300);
    font-family: 'ClashGrotesk-Bold', sans-serif;
    font-size: 40px;
    font-weight: 700;
    text-align: center;
}
  
.faq-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 340px;
    padding: 20px;
    border-radius: 20px;
    background: var(--White);
}
  
.question-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}
  
.question {
    color: var(--Black);
    font-family: 'Archivo Black', sans-serif;
    font-size: 16px;
    line-height: 127.523%;
    flex-grow: 1;
    margin: 0;
}
  
.open-close-icon {
    display: flex;
    background: none;
    padding: 0;
    cursor: pointer;
    border-radius: 2px;
    border: 1.3px solid rgba(31, 186, 163, 0.50);
    transform: rotate(0deg);
    transition: transform 0.3s linear;
}

.open-close-icon.rotated {
    transform: rotate(-180deg);
    transition: transform 0.3s linear;
}
  
hr {
    width: 100%;
    border: none;
    border-top: 1px solid var(--Black);
    margin: 0;
}

hr, .answer {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 0.3s linear, opacity 0.3s linear, margin-top 0.3s linear;
}
  
.faq-item.open hr, .faq-item.open .answer {
    max-height: 250px; /* Might be modified later, it influences transition timing*/
    opacity: 1;
    margin-top: 20px;
}
  
.answer {
    color: var(--Black);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    line-height: 127.523%;
    width: 100%;
    margin: 0;
}

@media (min-width: 1024px) {
    .faq-section {
        padding: 20px 67px;
    }

    .faq-item {
        width: 100%;
        max-width: 1075px;
    }
}
