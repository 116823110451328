.advantages-box {
    display: flex;
    width: 365px;
    height: 205px;
    position: relative;
}

.advantages-box.mirrored {
    flex-direction: row-reverse;
}

.circle-container,
.circle {
    width: 200px;
    height: 200px;
}

.circle {
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.65);
    box-shadow: 2px 2px 2px rgba(8, 84, 127, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
}

.circle-text {
    color: var(--White);
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 800;
    text-align: left;
    width: 142px;
    height: 69px;
    margin: 0 14px;
}

.texts-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 210px;
    margin-left: -45px;
    z-index: 1;
}

.text-item {
    width: 210px;
    height: 44px;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 800;
    line-height: 127.523%;
    box-shadow: 2px 2px 2px rgba(8, 84, 127, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.text-item:nth-child(odd) {
    background-color: var(--Grey-700);
    color: var(--White, #FFF);
}

.text-item:nth-child(even) {
    background-color: var(--White);
    color: var(--muted-blue, #7687A1);
}

.advantages-box.mirrored .texts-container {
    margin: 0 -45px 0 0;
}

.advantages-box.mirrored .circle {
    justify-content: flex-end;
}

.advantages-box.mirrored .circle-text {
    text-align: right;
}

@media (min-width: 1024px) {
    .advantages-box {
        width: 532px;
        height: 300px;
        margin: auto 0;
    }

    .circle-container,
    .circle {
        width: 300px;
        height: 300px;
    }

    .circle {
        background: rgba(89, 112, 34, 0.60);
    }

    .circle-text {
        font-size: 28px;
        width: 199px;
        height: 106px;
    }

    .texts-container {
        width: 315px;
        margin-left: -85px;
    }
    
    .text-item {
        width: 315px;
        height: 66px;
        font-size: 25px;
    }

    .text-item:nth-child(odd) {
        background-color: var(--Accent-3, #869B55);
    }
    
    .text-item:nth-child(even) {
        background-color: var(--White, #FFF);
    }
    
    .advantages-box.mirrored .texts-container {
        margin: 0 -85px 0 0;
    }

    .advantages-box.mirrored .text-item:nth-child(odd) {
        background-color: var(--Secondary-2, #D6A04D);
    }
    
    .advantages-box.mirrored .circle {
        background: rgba(190, 148, 22, 0.60);
    }
}
