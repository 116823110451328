.home-text {
    width: 240px;
    height: 180px;
    color: var(--Grey-300);
    text-align: right;
    align-self: end;
    font-size: 50px;
    font-family: 'ClashGrotesk-Regular', sans-serif;
    line-height: normal;
    flex-shrink: 0;
    margin-top: 10vh;
}

.buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: min(70px, 10vw);
    margin-bottom: 11.5vh;
}

.about-us-text {
    color: var(--White);
    text-align: center;
    font-family: 'Archivo Black', sans-serif;
    font-size: 30px;
    letter-spacing: 13.2px;
    flex-shrink: 0;
    justify-content: center;
}
.react-player {
    width: 100%;
    height: 100%;
  }

.player-wrapper {
    height: 200px;
    width: 350px;
  }

 @media (min-width: 430px) {
    .player-wrapper {
        height: 300px;
        width: 410px;
    }
}

@media (min-width: 630px) {
    .player-wrapper {
        height: 400px;
        width: 610px;
    }
}

@media (min-width: 830px) {
    .player-wrapper {
        height: 500px;
        width: 810px;
    }
}

/* Desktop view */
@media (min-width: 1024px) {
    .info-text-button-container {
    width: 100%;
    max-width: 1400px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    }

    .text-button-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        gap: 20px;
    }

    .home-text {
        width: 237px;
        height: 249px;
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

@media (min-width: 1500px) {
    .info-text-button-container {
        width: 100%;
        max-width: 1530px;
        align-items: flex-start;
        gap: 280px;
    }
}
